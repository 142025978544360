import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spinner from '../../components/spinner'
import { getUsers } from '../../services/users'
import { addVideosToGroup, addVideoToGroup, createVideoGroup, deleteUserToGroup, deleteVideoGroup, deleteVideoToGroup, editOrderRank, 
  getVideoGroupList, renameVideoGroup, getVideoGroupPasswords, createPasswordVideoGroups, updatePasswordVideoGroups } from '../../services/videoGroup'
import { getVideoList } from '../../services/videos'
import { removeFromArray } from '../../utils/array'

const VideoGroups = () => {

  const [isLoadingVideos, setIsLoadingVideos] = useState(false)
  const [isLoadingVideoGroups, setIsLoadingVideoGroups] = useState(false)
  const [isLoadingVideoGroupsPass, setIsLoadingVideoGroupsPass] = useState(false)
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [isAddingEditingPass, setIsAddingEditingPass] = useState(false)

  const [videoGroups, setVideoGroups] = useState([])
  const [videos, setVideos] = useState([])
  const [videosToGroup, setVideosToGroup] = useState([])
  const [videoToGroup, setVideoToGroup] = useState('')
  const [users, setUsers] = useState([])
  const [usersToGroup, setUsersToGroup] = useState([])
  const [groupName, setGroupName] = useState('')
  const [orderRank, setOrderRank] = useState()
  const [groupInfo, setGroupInfo] = useState(null)
  const [newGroupName, setNewGroupName] = useState('')
  const [newOrderRank, setNewOrderRank] = useState()
  const [showModal, setShowModal] = useState(false)
  const [groupInfoModal, setGroupInfoModal] = useState(false)
  const [addVideoModal, setAddVideoModal] = useState(false)

  const [videoGroupPasswords, setVideoGroupPasswords] = useState([])
  const [showCreatePassModal, setShowCreatePassModal] = useState(false)
  const [password, setPassword] = useState()
  const [passwordVideoGroupEntries, setPasswordVideoGroupEntries] = useState([])
  const [showPasswordVideoGroupsModal, setShowPasswordVideoGroupsModal] = useState(false)
  const [passwordVideoGroupToShow, setPasswordVideoGroupToShow] = useState(null)

  useEffect(() => {

    setIsLoadingVideos(true)
    setIsLoadingVideoGroups(true)
    setIsLoadingVideoGroupsPass(true)
    setIsLoadingUsers(true)

    const _getVideoGroups = async () => {
      const { data: _videoGroups } = await getVideoGroupList()
      setVideoGroups(_videoGroups)
      setIsLoadingVideoGroups(false)
    }
    
    _getVideoGroups()

    const _getVideoGroupPasswords = async () => {
      const { data: _videoGroupPasswords } = await getVideoGroupPasswords()
      setVideoGroupPasswords(_videoGroupPasswords)
      setIsLoadingVideoGroupsPass(false)
    }
    _getVideoGroupPasswords()

    const _getVideos = async () => {
      const { data: _videos } = await getVideoList()
      setVideos(_videos)
      setIsLoadingVideos(false)
    }
    _getVideos()

    const _getUsers = async () => {
      try {
        await getUsers().then((res) => {
          const users = res.data.filter(user => !user.roles || !user.roles.includes('Admin'));
          setUsers(users)
          setIsLoadingUsers(false)
        })
      } catch (e) {
        console.log(e.message)
      }
    }
    _getUsers()

  }, [])

  const handleVideosToGroup = (id) => {
    if (videosToGroup.some(vidId => vidId === id)) {
      let _videosToGroup = videosToGroup
      _videosToGroup = removeFromArray(_videosToGroup, id)
      setVideosToGroup([..._videosToGroup])
    } else {
      let _videosToGroup = videosToGroup
      setVideosToGroup([..._videosToGroup, id])
    }
  }

  const handleUsersToGroup = (id) => {
    if (usersToGroup.some(userId => userId === id)) {
      let _usersToGroup = usersToGroup
      _usersToGroup = removeFromArray(_usersToGroup, id);
      setUsersToGroup([..._usersToGroup]);
    } else {
      let _usersToGroup = usersToGroup
      setUsersToGroup([..._usersToGroup, id])
    }
  }

  const handleCreateVideo = async () => {
    const videoGroup = {
      'name': groupName,
      'videoIds': videosToGroup,
      'userIds': usersToGroup,
      'orderRank': orderRank
    }

    try {
      const { data: groupCreated } = await createVideoGroup(videoGroup)


      setShowModal(false)
      setGroupName('')
      setVideosToGroup([])
      setUsersToGroup([])

      let _groupCreated = {
        'id': groupCreated.id,
        'name': groupCreated.name,
        'videos': groupCreated.videoIds,
        'users': groupCreated.userIds,
        'orderRank': groupCreated.orderRank,
      }
      setVideoGroups([...videoGroups, _groupCreated])
    } catch (e) {
      console.log(e)
    }
  }

  const handleVideoGroupsToPassword = (id) => {
    if (passwordVideoGroupEntries.some(vidId => vidId === id)) {
      let _passwordVideoGroups = passwordVideoGroupEntries
      _passwordVideoGroups = removeFromArray(_passwordVideoGroups, id)
      setPasswordVideoGroupEntries([..._passwordVideoGroups])
    } else {
      let _passwordVideoGroups = passwordVideoGroupEntries
      setPasswordVideoGroupEntries([..._passwordVideoGroups, id])
    }
  }


  const handleCreatePassword = async () => {

    setIsAddingEditingPass(true);

    const passVideoGroups = {
      'password': password,
      'vgIds': passwordVideoGroupEntries.join(',')
    }

    try {
      const { data: passwordCreated } = await createPasswordVideoGroups(passVideoGroups)


      setShowCreatePassModal(false)
      setPassword('')
      setPasswordVideoGroupEntries([])

      let _passwordCreated = {
        'id': passwordCreated.id,
        'password': passwordCreated.password,
        'videoGroups': passwordCreated.videoGroups,
      }
      setVideoGroupPasswords([...videoGroupPasswords, _passwordCreated])
    } catch (e) {
      console.log(e)
    }

    setIsAddingEditingPass(false)
  }

  const handleShowPasswordVideoGroupsModal = (pass) => {
    setPasswordVideoGroupToShow(pass)
    setShowPasswordVideoGroupsModal(true)
    setPasswordVideoGroupEntries(pass.videoGroups.map(x => x.id))
  }

  const handleUpdateVideoGroupPassword = async () => {
    setIsAddingEditingPass(true)
    const payload = {
      'id': passwordVideoGroupToShow.id,
      'password': password ? password: passwordVideoGroupToShow.password,
      'vgIds': passwordVideoGroupEntries.join(',')
    }

    try {
      const { data: newList } = await updatePasswordVideoGroups(payload)

      setPasswordVideoGroupToShow(null)
      setShowPasswordVideoGroupsModal(false)
      setPassword('')
      setPasswordVideoGroupEntries([])
      
      setVideoGroupPasswords(newList)
    } catch (e) {
      console.log(e)
    }
    setIsAddingEditingPass(false)
  }

  const handleAddVideoToGroup = async (groupId, videoId) => {
    try {
      const { data: result } = await addVideoToGroup(groupId, videoId)
      setAddVideoModal(false)
      setVideoToGroup('')
      const _groupInfo = result.find(x => x.id === groupId)
      setGroupInfo(_groupInfo)
      setVideoGroups(result)
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddVideosToGroup = async (groupId) => {
    try {
      if (videosToGroup.length > 0) {
        console.log('selected ' + groupId)
        const videoIds = videosToGroup.join()
        const { data: result } = videosToGroup.length > 1 ? await addVideosToGroup(groupId, videoIds) : await addVideoToGroup(groupId, videoIds)
        setAddVideoModal(false)
        setVideoToGroup('')
        const _groupInfo = result.find(x => x.id === groupId)
        setGroupInfo(_groupInfo)
        setVideoGroups(result)
      }

    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteVideoToGroup = async (groupId, videoId) => {
    try {
      const { data: result } = await deleteVideoToGroup(groupId, videoId)
      const _groupInfo = result.find(x => x.id === groupId)
      setGroupInfo(_groupInfo)
      setVideoGroups(result)
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteUserToGroup = async (groupId, userId) => {
    try {
      const { data: result } = await deleteUserToGroup(groupId, userId)
      const _groupInfo = result.find(x => x.id === groupId)
      setGroupInfo(_groupInfo)
      setVideoGroups(result)
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteVideoGroup = async (groupId, index) => {
    try {
      await deleteVideoGroup(groupId)
      let _videoGroups = videoGroups
      _videoGroups.splice(index, 1)
      setVideoGroups([..._videoGroups])
    } catch (e) {
      console.log(e)
    }
  }

  const handleGroupInfo = (group) => {
    setGroupInfo(group)
    setGroupInfoModal(true)
  }

  const handleRenameVideoGroup = async (groupId) => {
    try {
      await renameVideoGroup(groupId, newGroupName)
      let _groupInfo = groupInfo
      _groupInfo.name = newGroupName
      setGroupInfo(_groupInfo)
      setGroupInfoModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleEditOrderRank = async (groupId) => {
    try {
      await editOrderRank(groupId, newOrderRank)
      let _groupInfo = groupInfo
      _groupInfo.orderRank = newOrderRank
      setGroupInfo(_groupInfo)
      setGroupInfoModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseAndUnselectItems = (show) => {
    setVideosToGroup([])
    setAddVideoModal(show)
  }

  const togglePassword = (index, passVisible) => {
    let tempVG = [...videoGroups]
    tempVG[index].passVisible = !passVisible
    setVideoGroups([...tempVG])
  }

  return <>
    {/* Add Video Modal */}
    <Modal
      show={addVideoModal}
      onHide={() => setAddVideoModal(false)}
      aria-labelledby="add-video-modal"
      size="lg"
      backdropClassName="level-2"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="add-video-modal">
          Add Video to {groupInfo && groupInfo.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="row-eq-height">{videos.filter(a => !(groupInfo && groupInfo.videos.some(b => b.id === a.id))).map((video, index) => (
          <Col className="mb-3" key={`video-${index}`} md="6" lg="4">
            <Card
              bg={videosToGroup.some(v => v === video.id) ? "primary" : "white"}
              text={videosToGroup.some(v => v === video.id) ? "white" : "dark"}
              className="h-100 " style={{ 'cursor': 'pointer' }} onClick={() => { handleVideosToGroup(video.id) }}>
              <div className="p-2">
                <p className="mb-0">{video.title}</p>
              </div>
            </Card>
          </Col>
        ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setAddVideoModal(false)}>Close</Button>
        <Button type="button" onClick={() => { handleAddVideosToGroup(groupInfo.id) }}>Add Video</Button>
      </Modal.Footer>
    </Modal>

    {/* Group Info Modal */}
    <Modal
      show={groupInfoModal}
      onHide={() => setGroupInfoModal(false)}
      aria-labelledby="group-info-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="group-info-modal">
          {groupInfo && groupInfo.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <p className="mb-2"><strong>Rename Group</strong></p>
          <div className="form-inline">
            <div className="form-group mb-2">
              <Form.Control style={{ width: '300px' }} className="mr-2" type="text" placeholder="Enter new group name" onChange={(event) => setNewGroupName(event.target.value)} />
              <Button onClick={() => handleRenameVideoGroup(groupInfo.id)}>Save</Button>
            </div>
          </div>
        </div>
        <Row>
          <Col xs="6">
            <div className="mb-3">
              <p className="mb-2"><strong>Edit Order Rank</strong></p>
              <div className="form-inline">
                <div className="form-group mb-2">
                  <Form.Control className="mr-2" type="number" placeholder="Edit order rank" onChange={(event) => setNewOrderRank(event.target.value)} />
                  <Button onClick={() => handleEditOrderRank(groupInfo.id)}>Save</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-3">
          <p className="mb-2"><strong>Videos</strong></p>
          <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
            {groupInfo && groupInfo.videos.map((video, index) => (
              <p className="mb-0 remove-icon-parent" key={`groupvideo-${index}`}>
                <Link className="text-dark" to={`/video/${video.id}`}>{video.name}</Link>
                <i onClick={() => handleDeleteVideoToGroup(groupInfo.id, video.id)} title="remove" className="remove-icon fas fa-times-circle text-muted cursor-pointer ml-1"></i>
              </p>
            ))}
          </div>
          <Button className="mt-2" size="sm" onClick={() => handleCloseAndUnselectItems(true)}>Add Video</Button>
        </div>
        {
          groupInfo && groupInfo.users.length > 0 &&

          <div>
            <p className="mb-2"><strong>Users</strong></p>
            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
              {groupInfo.users.map((user, index) => (
                <p className="mb-0 remove-icon-parent" key={`groupuser-${index}`}>
                  {user.name}
                  <i onClick={() => handleDeleteUserToGroup(groupInfo.id, user.id)} title="remove" className="remove-icon fas fa-times-circle text-muted cursor-pointer ml-1"></i>
                </p>
              ))}
            </div>
          </div>
        }
      </Modal.Body>
    </Modal>

    {/* Create Group Modal */}
    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="create-group-modal"
      centered
    >
      <Form id="create-group-form" noValidate>
        <Modal.Header closeButton>
          <Modal.Title id="create-group-modal">
            Create Video Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="9">
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='groupname'
                  placeholder='Group Name'
                  onChange={(e) => setGroupName(e.target.value)}
                  value={groupName}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Order Rank</Form.Label>
                <Form.Control
                  type='number'
                  name='orderrank'
                  placeholder='Order Rank'
                  onChange={(e) => setOrderRank(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Label>Select Videos to Add in Group</Form.Label>
          <div className="pr-3 mb-3" style={{ 'height': '200px', 'overflowY': 'auto' }}>
            <Row className="row-eq-height">{videos.map((video, index) => (
              <Col className="mb-3" key={`video-${index}`} md="6" lg="4">
                <Card
                  text={videosToGroup.some(v => v === video.id) ? "white" : "dark"}
                  bg={videosToGroup.some(v => v === video.id) ? "primary" : "white"} className="h-100 " style={{ 'cursor': 'pointer' }} onClick={() => handleVideosToGroup(video.id)}>
                  <div className="p-2">
                    <p className="mb-0">{video.title}</p>
                  </div>
                </Card>
              </Col>
            ))}
            </Row>
          </div>

          <Form.Label>Select Users</Form.Label>
          <div className="pr-3 mb-3" style={{ 'height': '100px', 'overflowY': 'auto' }}>
            <Row className="row-eq-height">{users.map((user, index) => (
              <Col className="mb-3" key={`user-${index}`} md="6" lg="4">
                <Card
                  text={usersToGroup.some(u => u === user.id) ? "white" : "dark"}
                  bg={usersToGroup.some(u => u === user.id) ? "primary" : "white"} className="h-100 " style={{ 'cursor': 'pointer' }} onClick={() => { handleUsersToGroup(user.id) }}>
                  <div className="p-2">
                    <p className="mb-0">{user.username}</p>
                  </div>
                </Card>
              </Col>
            ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModal(false)}>Close</Button>
          <Button type="button" onClick={() => handleCreateVideo()}>Create</Button>
        </Modal.Footer>
      </Form>
    </Modal>

    {/* Create Password Modal */}
    <Modal
      size="lg"
      show={showCreatePassModal}
      onHide={() => {setShowCreatePassModal(false);setPassword('');setPasswordVideoGroupEntries([]);}}
      aria-labelledby="create-password-modal"
      centered
    >
      <Form id="create-password-form" noValidate>
        <Modal.Header closeButton>
          <Modal.Title id="create-password-modal">
            Create Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="4">
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='password'
                  placeholder='Password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Label>Select Video Groups</Form.Label>
          <div className="pr-3 mb-3" style={{ 'height': '200px', 'overflowY': 'auto' }}>
            <Row className="row-eq-height">{videoGroups.map((vg, index) => (
              <Col className="mb-3" key={`vg-${index}`} md="6" lg="4">
                <Card
                  text={passwordVideoGroupEntries.some(v => v === vg.id) ? "white" : "dark"}
                  bg={passwordVideoGroupEntries.some(v => v === vg.id) ? "primary" : "white"} className="h-100 " style={{ 'cursor': 'pointer' }} onClick={() => handleVideoGroupsToPassword(vg.id)}>
                  <div className="p-2">
                    <p className="mb-0">{vg.name}</p>
                  </div>
                </Card>
              </Col>
            ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
        {isAddingEditingPass && <Spinner/>}
          <Button variant="light" onClick={() => setShowCreatePassModal(false)}>Close</Button>
          <Button type="button" onClick={() => handleCreatePassword()}>Create</Button>
        </Modal.Footer>
      </Form>
    </Modal>

    {/* Video Group Password Modal */}
    <Modal
        size="lg"
        show={showPasswordVideoGroupsModal}
        onHide={() => {setShowPasswordVideoGroupsModal(false);setPassword('');setPasswordVideoGroupEntries([]);}}
        aria-labelledby="vgp-info-modal"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="vgp-info-modal">
            {passwordVideoGroupToShow && passwordVideoGroupToShow.password}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <p className="mb-2"><strong>Change Password</strong></p>
            <div className="form-inline">
              <div className="form-group mb-2">
                <Form.Control style={{ width: '300px' }} className="mr-2" type="text" placeholder="Enter new password" onChange={(event) => setPassword(event.target.value)} />
               </div>
            </div>
          </div>
          <p className="mb-2"><strong>Video Groups</strong></p>
            <div className="pr-3 mb-3" style={{ 'height': '200px', 'overflowY': 'auto' }}>
              <Row className="row-eq-height">{videoGroups.map((vg, index) => (
                <Col className="mb-3" key={`vg-${index}`} md="6" lg="4">
                  <Card
                    text={passwordVideoGroupEntries.some(v => v === vg.id) ? "white" : "dark"}
                    bg={passwordVideoGroupEntries.some(v => v === vg.id) ? "primary" : "white"} className="h-100 " style={{ 'cursor': 'pointer' }} onClick={() => handleVideoGroupsToPassword(vg.id)}>
                    <div className="p-2">
                      <p className="mb-0">{vg.name}</p>
                    </div>
                  </Card>
                </Col>
              ))}
              </Row>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
          {isAddingEditingPass && <Spinner/>}
          <Button className="mt-2" onClick={() => handleUpdateVideoGroupPassword()}>Save</Button>
        </Modal.Footer>
      </Modal>

    { (isLoadingVideos || isLoadingVideoGroups || isLoadingVideoGroupsPass || isLoadingUsers) ? 
    
    <section><Spinner /></section>

    :

    <section>
      <Col>
        <Row className="mb-4">
          <Col style={{display:'flex'}}>
            <h5 style={{marginBottom: 0, alignSelf:'center', marginRight:5}}>Video Groups</h5>
            <Button onClick={() => setShowModal(true)} style={{fontSize:'.8rem'}}>Create</Button>
          </Col>
        </Row>
        <Row className="mb-4">{videoGroups.map((group, index) => (
          <Col key={`group-${index}`} lg="3" className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>
                  {group.name}
                  {/* <span onClick={() => handleDeleteVideoGroup(group.id)} title="remove-group" className="text-muted remove-icon" style={{'position':'absolute','right':'15px','top':'10px'}}>&times;</span> */}
                  {/* <i onClick={() => handleDeleteVideoGroup(group.id)} className="fa fa-times-circle remove-icon" title="remove-group" aria-hidden="true" style={{'position':'absolute','right':'15px','top':'10px'}}></i> */}
                </Card.Title>

                <p className="mb-3 text-muted">
                  <i className="fas fa-star" title="Order Rank" alt="Order Rank"></i>  Order Rank: {group.orderRank}<br />
                  <i className="fas fa-video"></i> {group.videos.length} videos &emsp;|&emsp;
                  <i className="fas fa-users"></i> {group.users.length} users
                </p>

                <Row>
                  <Col className="pr-1">
                    <Button onClick={() => handleGroupInfo(group)} variant="outline-primary w-100">Edit</Button>
                  </Col>
                  <Col className="pl-1">
                    <Button onClick={() => handleDeleteVideoGroup(group.id, index)} variant="outline-primary w-100">Delete</Button>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
        <Row className="mb-4">
          <Col style={{display:'flex'}}>
              <h5 style={{marginBottom: 0, alignSelf:'center', marginRight:5}}>Passwords</h5>
              <Button onClick={() => setShowCreatePassModal(true)} style={{fontSize:'.8rem'}}>Create</Button>
            </Col>
        </Row>
        <Row className="mb-4">{videoGroupPasswords.map((vgp, index) => (
          <Col key={`vgp-${index}`} lg="3" className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>
                  {vgp.password}
                </Card.Title>

                <p className="mb-3 text-muted">
                  <i className="fas fa-video"></i> {vgp.videoGroups.length} video groups
                </p>

                <Row>
                  <Col className="pr-1">
                    <Button onClick={() => handleShowPasswordVideoGroupsModal(vgp)} variant="outline-primary w-100">Edit</Button>
                  </Col>
                  {/* <Col className="pl-1">
                    <Button onClick={() => handleDeleteVideoGroup(group.id, index)} variant="outline-primary w-100">Delete</Button>
                  </Col> */}
                </Row>

              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
      </Col>
    </section>
   
   }
  </>
}

export default VideoGroups
